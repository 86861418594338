import { LoaderCircle } from 'lucide-react';
import { FC, HTMLProps } from 'react';
import { cn } from '~/lib/utils';

export const RobinSpinner: FC<HTMLProps<HTMLSpanElement>> = (props) => {
  return (
    <span {...props} className={cn('absolute top-1/2 right-2 transform -translate-y-1/2', props.className)}>
      <LoaderCircle className="flex animate-spin duration-700" />
    </span>
  );
};
