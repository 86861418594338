import * as React from 'react';
import { IconName, renderIcon } from '~/components/robin/RobinIcons';
import { RobinSpinner } from '~/components/robin/RobinSpinner';
import { Button } from '~/components/ui/button';
import { RobinBtnStyle } from '~/config/enums';
import { RobinBtnStyles } from '~/config/styles';
import { cn } from '~/lib/utils';

interface RobinButtonProps {
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
  btnStyle?: RobinBtnStyle;
  iconName?: IconName | null;
  onClick?: () => void;
  children: React.ReactNode;
  className?: string; // Optional className prop for additional styling
  disabled?: boolean;
  isLoading?: boolean;
}

export default function RobinButton({ btnStyle = RobinBtnStyle.Primary, iconName, onClick, children, className = '', isLoading = false, disabled = isLoading, type }: RobinButtonProps) {
  const baseClass = `
    robin-medium-16
    flex justify-center items-center
    w-full lg:max-w-lg xl:max-w-xl mx-auto
    px-4 gap-2 h-14
    border-2 rounded-lg
    focus:outline-none
    relative
  `;

  return (
    <Button onClick={disabled ? undefined : onClick} className={cn(baseClass, RobinBtnStyles[btnStyle], disabled && RobinBtnStyles[RobinBtnStyle.Disabled], className)} disabled={disabled} type={type}>
      <div className="flex flex-row items-center gap-2">
        {iconName && renderIcon(iconName)}
        {children}
        {isLoading && <RobinSpinner />}
      </div>
    </Button>
  );
}
