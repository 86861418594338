import { Route } from '~/config/enums';
import { currentService } from '~/lib/service-detector';

interface MetaTagOptions {
  title?: string;
  route?: Route;
}

// Meta
export function generateMetaTags({ title, route }: MetaTagOptions) {
  const APP_URL = 'https://robinfeed.com';
  const service = currentService();
  const pageTitle = title ? `${service} | ${title}` : service;
  const keywords =
    service === 'RobinFeed'
      ? 'Robin Feed, decentralized social media, AI, AT Protocol, BlueSky, SpaceHost'
      : 'Robin Live, decentralized social media search engine, integrated with AI, AT Protocol, BlueSky, SpaceHost';
  const description =
    service === 'RobinFeed' ? 'Personalized feed generator for decentralized social media.' : 'Decentralized social media search engine integrated with AI, AT Protocol, BlueSky, SpaceHost.';
  const url = route ? new URL(route, APP_URL).toString() : APP_URL;
  const image = `${APP_URL}/robinfeed.png`;

  return [
    { title: pageTitle },
    { name: 'keywords', content: keywords },
    { name: 'description', content: description },
    { property: 'og:title', content: pageTitle },
    { property: 'og:description', content: description },
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: url },
    { property: 'og:image', content: image },
    { viewport: 'width=device-width, initial-scale=1.0' },
  ];
}
