import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '@/lib/utils';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'bg-rf-primary text-primary-foreground hover:bg-rf-primary/90 border-2 border-black',
        destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        'secondary-destructive': 'bg-rf-red/5 text-rf-red hover:bg-rf-red/10 focus-visible:bg-rf-red/10 focus:bg-rf-red/10',
        outline: 'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
        secondary: 'bg-rf-gray-f9 text-rf-gray-75 hover:bg-rf-gray-75/10 focus-visible:bg-rf-gray-75/10 focus:bg-rf-gray-75/10',
        ghost: 'text-[#828282] hover:bg-rf-gray-75/10 focus-visible:bg-rf-gray-75/10 focus:bg-rf-gray-75/10',
        link: 'text-primary underline-offset-4 hover:underline',
      },
      size: {
        default: 'h-10 px-4 py-2',
        sm: 'h-9 rounded-md px-3',
        lg: 'h-11 rounded-md px-8',
        icon: 'py-1.5 px-2 rounded-2xl gap-0.5 robin-demibold-12 h-10 w-10',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({ className, variant, size, asChild = false, ...props }, ref) => {
  const Comp = asChild ? Slot : 'button';
  return <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props} />;
});
Button.displayName = 'Button';

export { Button, buttonVariants };
